const { REACT_APP_MAPBOX_API_TOKEN } = process.env;

export const administrationPermissionApprovalTabs: { [key: string]: string } = {
    groupApproval: 'Group',
    subRegionApproval: 'Local Association',
    regionApproval: 'Country',
    buildingApproval: 'Building'
};

export const domPurifyConfig = {
    ADD_ATTR: ['target']
};

export const excludedErrorStatusCodes = [401, 403];

export const companyTypes = {
    propertyManagement: 'Property Management',
    propertyOwner: 'Property Owner',
    admin: 'Admin'
};

export const featureFlags = {
    secondaryVerification: 'show_verification_transition',
    notes: 'show_notes',
    publicDashboard: 'show_public_dashboard',
    bonusQuestions: 'bonus_question',
    verificationLog: 'show_verification_log',
    emailNotifications: 'opt_out_email_notifications',
    publicDashboardTranslations: 'show_public_dashboard_translations',
    questionnaireExpiry: 'show_alert_notification_for_questionnarie_expiry'
};

export const forbiddenErrorReachOutUrl = `info@bomabest.org`;

export const modulePermissions = {
    propertiesBuildings: 'can_access_properties_buildings',
    propertiesBuildingsFilesCheckBox: 'can_access_properties_buildings_files_check_box',
    propertiesBuildingsInvoices: 'can_access_properties_buildings_invoice',
    propertiesPortfolio: 'can_access_properties_portfolio',
    propertiesPortfolioAssignCertifiedLevel: 'can_assign_certified_level',
    usermanagementUsers: 'can_access_usermanagement_users',
    userManagementCompanies: 'can_access_usermanagement_companies',
    userManagementSecondaryCompanies: 'can_access_usermanagement_connected_companies',
    administrationCertification: 'can_access_administration_certification',
    administrationPermission: 'can_access_administration_permission',
    administrationPermissionGroupApproval: 'can_access_administration_permission_groupapproval',
    administrationPermissionRegion: 'can_access_administration_permission_region',
    administrationPermissionSubRegion: 'can_access_administration_permission_subregion',
    administrationPermissionBuilding: 'can_access_administration_permission_building',
    administrationVerficationQuestion: 'can_access_administration_verificationquestion',
    administrationRegion: 'can_access_administration_region',
    administrationNotifications: 'can_access_administration_notifications',
    verificationAssignVerification: 'can_access_verification_assignverification',
    verificationStartVerification: 'can_access_verification_startverification',
    verificationAdminVerification: 'can_access_verification_adminverification',
    verificationRevertVerification: 'can_revert_verification',
    documentBOMADocuments: 'can_access_documents_bomadocuments',
    documentsCompanyDocuments: 'can_access_documents_companydocuments',
    documentsVerifierDocuments: 'can_access_documents_verificationdocuments',
    benchmarking: 'can_access_benchmarking',
    questionnaire: 'can_access_questionnaire',
    finances: 'can_access_finances',
    financesInvoiceConfiguration: 'can_access_finance_invoice_configuration',
    financesBuildingInvoices: 'can_access_finance_building_invoices',
    userProfileLogin: 'can_login_as_other_user',
    dashboardSummaryAccess: 'can_view_dashboard_summary',
    scoreReportDashboard: 'can_view_dashboard_score_report',
    completedVerifications: 'can_view_verification',
    answerReportDashboard: 'can_view_dashboard_answer_report',
    overviewDashboard: 'can_view_dashboard_overview',
    verificationVerifierDashboard: 'can_view_dashboard_verification_verifier',
    verificationSummaryDashboard: 'can_view_dashboard_verification_summary',
    verificationOverviewDashboard: 'can_view_dashboard_verification_overview',
    benchmarkingEnergyDashboard: 'can_view_dashboard_benchmarking_energy',
    benchmarkingOverviewDashboard: 'can_view_dashboard_benchmarking_overview',
    benchmarkingWasteDashboard: 'can_view_dashboard_benchmarking_waste',
    benchmarkingWaterDashboard: 'can_view_dashboard_benchmarking_water',
    requestAccess: 'can_request_access',
    canRefreshInvoices: 'can_refresh_invoices',
    canApplyInvoicePromoCode: 'can_apply_promo_code',
    canPayUsingPaypal: 'can_pay_using_paypal',
    exportVerification: 'can_export_verifications'
};

export const companyHyperLinks = {
    subRegionPolicies: 'https://bomacanada.ca/find-your-local-boma/',
    programPolicies: ' https://bomabestfieldguide.org/user-manual/streams/',
    pullFromEnergyStar:
        'https://energystar-mesa.force.com/PortfolioManager/s/article/How-do-I-add-a-contact-1600088534041',
    assetClasses: 'https://bomabestfieldguide.org/user-manual/about-asset-classes/',
    multipleBuildings: 'https://bomabestfieldguide.org/user-manual/multiple-buildings/'
};

export const mapBoxToken = REACT_APP_MAPBOX_API_TOKEN;

export const mapBoxInitialValues = {
    lat: 43.65532794005286,
    lng: -79.38034181544688,
    defaultZoom: 12
};
