export const colors: Record<string, string> = {
    Healthcare: '#A9C4E5',
    'Multi Unit Residential Buildings': '#546AAA',
    Universal: '#388968',
    'Light Industrial': '#A65A88',
    'Enclosed Shopping Center': '#8884d8',
    Office: '#F0BA61',
    'Open Air Retail': '#DFACD2'
};

export const START_YEAR_FOR_CERTIFICATION_AWARDED = 2021;
