import React from 'react';
import { Card } from '../../../../Dashboards/styles';
import { FlexColumn } from '../../../../styles';
import { constantStrings } from '../../../../../constantStrings';
import { useTranslation } from 'react-i18next';
import { buildingDataTypes } from '../../../types';
import { START_YEAR_FOR_CERTIFICATION_AWARDED } from '../../../constants';

const CardsComponent = ({ filteredBuildingData }: any) => {
    const { t } = useTranslation();
    const uniqueBuildingsData = filteredBuildingData.buildingData.reduce(
        (arr: any, current: any) => {
            const index = arr?.findIndex((item: any) => item.buildingName === current.buildingName);
            if (index === -1) arr.push(current);
            return arr;
        },
        []
    );

    const activeCertificationsInCurrentYear = () => {
        return (
            filteredBuildingData.buildingData.filter((buildingdata: buildingDataTypes) => {
                const issueYear = new Date(buildingdata.certificationIssueDate).getFullYear();
                const expiryYear = new Date(buildingdata.certificationExpiryDate).getFullYear();
                return (
                    issueYear >= START_YEAR_FOR_CERTIFICATION_AWARDED &&
                    new Date().getFullYear() >= issueYear &&
                    new Date().getFullYear() <= expiryYear
                );
            }).length || 0
        );
    };

    const calculateTotalArea = () => {
        let totalArea = 0;

        uniqueBuildingsData
            .filter((buildings: any) => buildings.grossFloorArea)
            .map((buildings: any) => {
                return (totalArea += parseInt(buildings.grossFloorArea));
            });
        return totalArea.toLocaleString();
    };

    const cardData: { value: string | number; name: string; title?: string | null }[] = [
        {
            value: activeCertificationsInCurrentYear(),
            name: `${t(constantStrings.bomaBestCertifications)} ${new Date().getFullYear()}`,
            title: `${t(constantStrings.bbProperties)} - ${activeCertificationsInCurrentYear()}`
        },
        {
            value: `${calculateTotalArea()}`,
            name: t(constantStrings.certifiedBuildingAreaSquareFeet)
        }
    ];

    return (
        <FlexColumn className={'flex-row flex-lg-column gap-1 flex-fill'}>
            {cardData.map((item) => (
                <div className='d-flex flex-fill' key={item.name}>
                    <Card className='flex-grow-1 flex-fill h-100'>
                        <div className='card-body justify-content-center gap-3'>
                            <div className='d-flex justify-content-between'>
                                <div className='card-text' title={item.value.toString()}>
                                    {item.value}
                                </div>
                            </div>
                            <div className='card-title' title={item.title || item.name}>
                                {item.name}
                            </div>
                        </div>
                    </Card>
                </div>
            ))}
        </FlexColumn>
    );
};

export default CardsComponent;
